class ApplicantWidget {
    constructor({ reportKey, onSuccess, onError = () => {}, onClose = () => {}}) {
        this.onSuccess = onSuccess;
        this.onError = onError;
        this.onClose = onClose;
        this.reportKey = reportKey;

        window.addEventListener('message', this.handleWidgetMessage.bind(this));
    }

    handleWidgetMessage(event) {
        const message = JSON.parse(event.data);

        this.validateMessageFormat(message);

        switch (message.name) {
            case 'applicant-widget-closed': return this.dispatchOnClose();

            case 'applicant-widget-error': return this.onError(message.data);

            case 'applicant-widget-success': return this.onSuccess(message.data);
        }
    }

    validateMessageFormat(message) {
        if (typeof message !== 'object' || ! Object.prototype.hasOwnProperty.call(message, 'name')) {
            throw WidgetException.invalidEvent();
        }
    }

    removeWidget() {
        document.getElementById('clearchecks-applicant-widget').remove();
    }

    dispatchOnClose() {
        this.removeWidget();
        this.onClose();
    }

    open() {
        this.generateBase();
        this.generateIframe();
    }

    generateBase() {
        let clearChecksApplicantWidget = document.createElement('div');

        clearChecksApplicantWidget.id = 'clearchecks-applicant-widget';

        clearChecksApplicantWidget.appendChild(
            document.createElement('div')
        );

        document.body.appendChild(clearChecksApplicantWidget);
    }

    generateIframe() {
        let iframe = document.createElement('iframe');

        iframe.src = `${process.env.MIX_APP_URL}/widget/applicant/${this.reportKey}`;
        iframe.height = '100%';
        iframe.width = '100%';
        iframe.title = 'ClearChecks Applicant Form';
        iframe.style = 'inset: 0px; position: fixed; z-index: 2147483646; border: 0; top: 0; left: 0;';

        document.querySelector('#clearchecks-applicant-widget div').appendChild(iframe);
    }
}

window.ClearChecksApplicantWidget = class ClearChecksApplicantWidget {
    static init(initializationObject) {
        this.validate(initializationObject);

        return new ApplicantWidget(initializationObject);
    }

    static validate(initializationObject) {
        let properties = this.getMissingProperties(initializationObject);

        if (properties.length > 0) {
            throw WidgetException.missingRequiredProperties(properties);
        }

        if (this.hasWrongPropertyTypes(initializationObject)) {
            throw WidgetException.wrongPropertyTypes();
        }
    }

    static getMissingProperties(initializationObject) {
        let requiredProperties = ['reportKey', 'onSuccess'];

        for (let key in Object.keys(initializationObject)) {
            requiredProperties.splice(requiredProperties.indexOf(key), 1);
        }

        return requiredProperties;
    }

    static hasWrongPropertyTypes(initializationObject) {
        return typeof initializationObject.reportKey !== 'string'
            || typeof initializationObject.onSuccess !== 'function'
            || (initializationObject.onClose && typeof initializationObject.onClose !== 'function')
            || (initializationObject.onError && typeof initializationObject.onError !== 'function');
    }
};

class WidgetException {
    constructor(message) {
        this.message = message;
    }

    static missingRequiredProperties(properties) {
        return new this(`The widget is missing required properties: ${JSON.stringify(properties)}`);
    }

    static wrongPropertyTypes() {
        return new this('The widget\'s properties need to be from the right types.');
    }

    static invalidEvent() {
        return new this('The event returned from the widget is invalid.');
    }
}
